<template>
  <div class="app-content pt-3 p-md-3 p-lg-4">
    <div class="container-xl">
      <h1 class="app-page-title">Write prescription</h1>
      <hr class="mb-4" />
      <div class="row g-4 settings-section">
        <div class="col-12 col-md-12">
          <div class="app-card app-card-settings shadow-sm p-4">
            <div class="app-card-body">
                <form method="post" @submit.prevent="save()">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="patient_name" class="form-label">Entry Date</label>
                                <input type="text" class="form-control" v-model="appointment.custom_today_date" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="patient_name" class="form-label">Appointment Date</label>
                                <input type="text" class="form-control" v-model="appointment.custom_appointment_date" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Appointment Time</label>
                                <input type="text" class="form-control" id="patient_name" v-model="appointment.time" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Patient name</label>
                                <input type="text" class="form-control" id="patient_name" placeholder="Patient name" v-model="appointment.patient_name" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Email</label>
                                <input type="text" class="form-control" v-model="appointment.e_mail" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Mobile Number</label>
                                <input type="text" class="form-control" v-model="appointment.mobile_number" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Age</label>
                                <input type="text" class="form-control" v-model="appointment.age" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Village</label>
                                <input type="text" class="form-control" v-model="appointment.village" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Road No./House No./Block</label>
                                <input type="text" class="form-control" v-model="appointment.road_no" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Post Office</label>
                                <input type="text" class="form-control" v-model="appointment.post_office" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Police Station</label>
                                <input type="text" class="form-control" v-model="appointment.upazilla" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">District</label>
                                <input type="text" class="form-control" v-model="appointment.district" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Gender</label>
                                <input type="text" class="form-control" v-model="appointment.gender" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Main Disease</label>
                                <input type="text" class="form-control" v-model="appointment.disease" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Prescription Date & Time</label>
                                <input type="text" v-if="prescriptions.length > 0" class="form-control" v-model="prescriptions[0].prescription_written_date" disabled>
                                <input type="text" v-else class="form-control" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Whats App Number</label>
                                <input type="text" class="form-control" v-model="appointment.whats_app_number" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Marital status</label>
                                <input type="text" class="form-control" v-model="appointment.marital_status" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Country</label>
                                <input type="text" class="form-control" v-model="appointment.country" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Treated</label>
                                <!-- <input type="text" class="form-control" v-model="appointment.treated" disabled> -->
                                <textarea class="form-control" v-model="appointment.treated" style="height : 70px;" disabled></textarea>
                            </div>
                        </div>
                         <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Teatment</label>
                                <input type="text" class="form-control" v-model="appointment.payment_type" disabled>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Payment বিকাশ নাম্বার/ Transaction ID</label>
                                <input type="text" class="form-control" v-model="appointment.transaction_id" >
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label for="approval" class="form-label" style="padding-right:10px">Approval</label>
                                <input id="approval"    type="checkbox"  v-model="appointment.approval" v-bind:checked="this.appointment.approval =='1'">
                            </div>
                        </div>
                       
                        <div class="col-md-12">
                            <table class="table table-sm table-boredered">
                                <thead class="text-center">
                                    <tr>
                                        <th>Medicine Name</th>
                                        <th>Description</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(prescription, ind) in prescriptions.filter(p => p.activated)" :key="ind">
                                        <td>
                                            <textarea class="form-control" v-model="prescription.medicine_name" placeholder="Enter medicine name" style="height : 100px;font-size: 20px"></textarea>
                                        </td>
                                        <td>
                                            <textarea class="form-control" v-model="prescription.description" placeholder="Enter medicine description" style="height : 100px;font-size: 20px"></textarea>
                                        
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-primary mr-1" @click.prevent="generateMedicine()">+</button>
                                            <button class="btn btn-sm btn-danger" @click.prevent="removePrescription(prescription, ind)">X</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p>
                        <button type="submit" class="btn btn-sm btn-primary" :disabled="isLoading">Save Prescription</button>
                    </p>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data: () => ({
        isLoading: false,
       
        appointment: {
            patient_name: '',
            approval: '',
        },
        prescriptions: [ ]
    }),
    async created() {
        await this.$store.dispatch('appointment/getAppointment',this.$route.params.id);
        this.appointment = await this.$store.getters['appointment/appointment'];
        await this.getPrescription();
    },
    methods: {
        getPrescription: async function() {
            await this.$store.dispatch('prescription/getPrescriptions', this.appointment.id);
            let prescriptionArray = await this.$store.getters['prescription/prescriptions'];
            if(prescriptionArray[0].prescriptions.length > 0){
                this.prescriptions = prescriptionArray[0].prescriptions;
                // this.prescriptions.push(this.appointment.transaction_id);
            }else{
                this.generateMedicine();
            }
        },
        generateMedicine: function() {
            let prescription = {
                id: 0,
                appointment_id: this.appointment.id,
                transaction_id: this.appointment.transaction_id,
                medicine_name: '',
                description: '',
                approval:  this.appointment.approval,
                activated: true
            }
            this.prescriptions.push(prescription);
        },
        removePrescription: function(prescription, ind) {
            if(prescription.id > 0)
                prescription.activated = false;
            else
                this.prescriptions.splice(ind,1);
        },
        save: async function() {
            let validated = true;
            this.isLoading = true;

            if(this.prescriptions.length <= 0) {
                alert("Your prescription empty");
                this.isLoading = false;
                return;
            }

            this.prescriptions.map(prescription => {
               

                prescription.transaction_id = this.appointment.transaction_id;
                prescription.approval = this.appointment.approval;
                if(prescription.medicine_name == '' || prescription.medicine_name == null) {
                    alert("Please enter medicine name");
                    validated = false;
                    return;
                }
            })
            
            if(!validated) {
                this.isLoading = false;
                return;
            }
            let success = await this.$store.dispatch('prescription/save', {prescriptions: this.prescriptions});
            if(success){
                alert("Prescription written successfully");
            }
            this.isLoading = false;
        }
    }
}
</script>